<template>
    <KCourse loader-class="MBcont" course-id="4" title="Съемный костюм XIX в." :items="items">
        <div class="MBtextcont">
            <h3>3.1 Моделируем юбку</h3>
        </div>
        
        <VideoView video-id="1c4ed91bc1624c41a35cff4b3aef464e"/>

        <div class="MBtextcont">
            <h3>3.2 Моделируем панталоны</h3>
        </div>
        
         <VideoView video-id="423fa5b19a874060bb702187e088e6d6"/>

        <div class="MBtextcont">
            <h3>3.3 Моделируем верхнее платье</h3>
        </div>
        
        <VideoView video-id="6e6c6dfb02594aad8959ad3e5398f793"/>

        <div class="MBtextcont">
            <h3>3.4 Моделируем 4 вида рукавов</h3>
            <p>Бонусное занятие</p>
        </div>
        
        <VideoView video-id="e031a068b1ac4e76881d5cd1f434c8c8"/>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Подготовка", url:"/mycourses/costume/1"},
                { title: "Снимаем выкройку", url:"/mycourses/costume/2"},
                { title: "Моделируем", url:"/mycourses/costume/3"},
                { title: "Ткань. Раскрой", url:"/mycourses/costume/4"},
                { title: "Нижнее белье", url:"/mycourses/costume/5"},
                { title: "Шьем полочку", url:"/mycourses/costume/6"},
                { title: "Дошиваем платье", url:"/mycourses/costume/7"},
                { title: "Красное платье", url:"/mycourses/costume/8"},
                { title: "Делаем обувь", url:"/mycourses/costume/9"},
                { title: "Ручные швы", url:"/mycourses/costume/10"},
                { title: "Машинные швы", url:"/mycourses/costume/11"},
                { title: "Утюжка", url:"/mycourses/costume/12"},
            ],
        }
        }
    }
</script>